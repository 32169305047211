import Vue from 'vue';
import MainHeader from '../Components/MainHeader.vue';
import { MixinUser } from '../common/MixinUser';
import { MixinDynamicFooter } from '../common/MixinDynamicFooter';

const es6Promise = require('es6-promise');

if (!global.Promise) {
  es6Promise.polyfill();
}

const v = new Vue({
  el: '#app',
  components: {
    'main-header': MainHeader,
  },
  mixins: [MixinUser, MixinDynamicFooter],
  data: {
    removedContent: null,
    errorMsg: null,
  },
  computed: {
    rischioClima() {
      return this.contact.profilo.products && this.removedContent == 'protezione-clima';
    },
    text() {
      return this.rischioClima ? `Se vuoi tutelare la tua azienda da rischi catastrofali scopri <br />
      <strong>Protezione rischio clima</strong> la soluzione pensata da SACE su misura per te ` : this.errorMsg;
    },
  },
  watch: {},
  mounted() {
    const mainElement = document.querySelector('main');
    this.removedContent = mainElement.dataset.removedContent;
    this.errorMsg = mainElement.dataset.errorMsg;
  },
  methods: {},
});
